import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCgjDxemLiKHj0YJlunzCDvQNT1NKA2hnU",
  authDomain: "chat-hub-1.firebaseapp.com",
  projectId: "chat-hub-1",
  storageBucket: "chat-hub-1.appspot.com",
  messagingSenderId: "435526947076",
  appId: "1:435526947076:web:8465e2b856085055020051",
  measurementId: "G-8P4Q5M8852",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export { auth, app };
